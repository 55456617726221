<template>
    <div class="con-wrap">
        <CarrotTitle title="전임교수(MI) 휴가 등록">
        </CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>
                    <div class="mb-20">
                        <span class="float-right">＊필수 입력 항목입니다.</span>
                        <div class="clear"></div>
                    </div>
                    <table class="table-row">
                        <colgroup>
                            <col width="180">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th><span class="txt-red">*</span> 강사명</th>
                                <td>
                                    <select class="w-400px" v-model="add.idx_tutor">
                                        <option value="">선택</option>
                                        <option :value="irow.idx" v-for="(irow, i) in comp.tutorList" :key="i">{{ irow.ename }}</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 구분</th>
                                <td>
                                    <label><input type="radio" v-model="add.vtype" value="full"><span class="ml-5 mr-20"> 연차</span></label>
                                    <label><input type="radio" v-model="add.vtype" value="am"><span class="ml-5 mr-20"> 오전 반차</span></label>
                                    <label><input type="radio" v-model="add.vtype" value="pm"><span class="ml-5 mr-20"> 오후 반차</span></label>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 기간</th>
                                <td>
                                    <CarrotDatePicker v-model.sync="add.sdate" class="inline-block"></CarrotDatePicker>
                                    ~ 
                                    <CarrotDatePicker v-model.sync="add.edate" class="inline-block"></CarrotDatePicker>
                                </td>
                            </tr>
                            <tr>
                                <th>비고</th>
                                <td>
                                    <input type="text" v-model.trim="add.title" class="w-100per" maxlength="50">
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <button @click="add.doCancel" class="btn-default float-left mt-30">취소</button>
                    <button @click="add.doSubmit" class="btn-default float-right mt-30">등록</button>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
// @ is an alias to /src 
import { onMounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue'
import Swal from 'sweetalert2'
import { useStore } from 'vuex';


export default {
    layout:"ResourceManagement",
    components: {
        CarrotDatePicker
    },
    setup() {
        const router = useRouter();
        const toast  = useToast()
        const store  = useStore();

        const add = reactive({
            idx_tutor  : "",
            vtype      : "full",
            sdate      : "",
            edate      : "",
            stime      : "",
            etime      : "",
            days       : [],
            title      : "",

            doCancel: () => {
                router.go(-1);
            },

            doSubmit : () => {
                let params = {
                    idx_tutor    : add.idx_tutor,
                    sdate        : add.sdate,
                    edate        : add.edate,
                    vtype        : add.vtype,
                    stime        : add.stime,
                    etime        : add.etime,
                    sday         : add.days,
                    title        : add.title,
                };

                if( !params.idx_tutor ){
                    toast.error("강사를 선택하세요.");
                    return;
                }
                if( !params.vtype ){
                    toast.error("구분을 선택하세요.");
                    return;
                }
                if( !params.sdate || !params.edate ){
                    toast.error("기간을 선택하세요.");
                    return;
                }

                axios.post("/rest/resourceManagement/addVacation", params).then((res) => {
                    if( res.data.err == 0 ){
                        Swal.fire({
                            title: '전임교수(MI) 휴가 등록',
                            text : '저장하였습니다.'
                        }).then(() => {
                            router.push({
                                name : 'ResourceManagement-MIScheduleDate'
                            });
                        });
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },
        });

        const comp = reactive({
            tutorList: [],
            dayList: [ '월', '화', '수', '목', '금', '토', '일' ],

            getTutorList: () => {
                let params = {
                    page : 1,
                    rows : 1000
                };
                axios.get('/rest/resourceManagement/getTutorList', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        comp.tutorList = res.data.list;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            }
        });

        onMounted(() => {
            // Mounted
            if( store.state.idx_team != 66 ) {
                router.go(-1);
                Swal.fire({
                    title : '전임교수(MI) 휴가 등록',
                    text  : '접근 권한이 없습니다.'
                });
                return;
            }

            comp.getTutorList();
        });

        return {comp, add};
    }
}
</script>

<style lang="scss" scoped>
.input-lock {
    position:absolute; top:0; left:0; width:100%; height:100%; z-index:2; background-color:rgba(0,0,0,0.001);
}
</style>